<template>
  <v-container>
    <v-progress-circular
        v-if="isLoading"
        :size="50"
        :width="10"
        color="#ffbe66"
        indeterminate
    ></v-progress-circular>
    <v-row v-else>
      <v-col sm="8">
        <v-card>
          <v-card-title>Ordering "{{ product.title }}". Please fill these fields.</v-card-title>
          <v-card-text>
            <v-alert
                v-model="NOTIFICATION_STATUS"
                :color="NOTIFICATION_COLOR"
                elevation="24"
                dismissible
            >{{ NOTIFICATION }}
              <p v-for="(error, index) in err" :key="index">
                {{ error }}
              </p>
            </v-alert>
            <v-form v-model="valid">
              <v-row>
                <v-col sm="4">
                  <v-text-field v-model="name"
                                :rules="NameRules"
                                label="Receiver Name"
                                color="#39bf88"
                                outlined>
                  </v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field v-model="phone"
                                :rules="PhoneRules"
                                label="Receiver Phone"
                                color="#39bf88"
                                outlined>
                  </v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field v-model="zipcode"
                                :rules="zipcodeRules"
                                label="Zip Code"
                                outlined
                                color="#39bf88">
                  </v-text-field>
                </v-col>
                <v-col sm="12">
                  <v-text-field v-model="address"
                                :rules="AddressRules"
                                label="Address"
                                outlined
                                color="#39bf88">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="#39bf88"
                rounded
                height="60px"
                style="box-shadow: none!important;"
                v-if="!valid"
                width="200px"
                disabled>
              Submit
            </v-btn>
            <v-btn
                color="#39bf88"
                rounded
                height="60px"
                width="200px"
                style="box-shadow: none!important;"
                v-else
                @click="submitOrder">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="4">
        <product-card :product="product"></product-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductCard from "@/components/ProductCard";

export default {
  name: "Order",
  components: {ProductCard},
  data() {
    return {
      name: null,
      NameRules: [
        v => !!v || 'Receiver name is required',
      ],
      phone: null,
      PhoneRules: [
        v => !!v || 'Receiver phone number is required',
      ],
      zipcode: null,
      zipcodeRules: [
        v => !!v || 'Zipcode is required',
      ],
      address: null,
      AddressRules: [
        v => !!v || 'Address is required',
      ],
      err: null,
      product: {},
      valid: null,
      isLoading: true,
    }
  },
  mounted() {
    window.axios.get(`api/books/${this.$route.params.productId}?flag=order`)
        .then((data) => {
          this.product = data.data.data
          if (this.product.type != 'book') {
            this.$router.push('/')
          }
          this.isLoading = false
        }).catch((error) => {
      if (error.response.status == 403) {
        this.isLoading = false,
        this.NOTIFICATION = 'You must have a post plan to order books.';
        this.NOTIFICATION_STATUS = true;
        this.NOTIFICATION_COLOR = 'red'
        let _this = this
        setTimeout(() => {
          _this.$router.push('/plans')
        }, 2000)
      }
    })
  },
  methods: {
    submitOrder() {
      window.axios.post('api/order/' + this.$route.params.productId, {
        receiver_name: this.name,
        receiver_number: this.phone,
        address: this.address,
        zip_code: this.zipcode
      }).then(() => {
        this.NOTIFICATION = "Order Submitted Successfully!"
        this.NOTIFICATION_COLOR = 'green'
        this.NOTIFICATION_STATUS = true
        this.$router.push('/orders')
      }).catch((err) => {
        this.NOTIFICATION_STATUS = true,
            this.NOTIFICATION_COLOR = 'red',
            this.err = err.response.data.errors
      })
    }
  }
}
</script>

<style scoped>

</style>